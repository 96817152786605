import request from '@/utils/request'

// 查询车辆任务列表
export function listVehicleTask(query) {
  return request({
    url: '/iot/vehicleTask/list',
    method: 'get',
    params: query
  })
}

// 查询车辆任务详细
export function getVehicleTask(id) {
  return request({
    url: '/iot/vehicleTask/' + id,
    method: 'get'
  })
}

// 新增车辆任务
export function addVehicleTask(data) {
  return request({
    url: '/iot/vehicleTask',
    method: 'post',
    data: data
  })
}

// 修改车辆任务
export function updateVehicleTask(data) {
  return request({
    url: '/iot/vehicleTask',
    method: 'put',
    data: data
  })
}

// 删除车辆任务
export function delVehicleTask(id) {
  return request({
    url: '/iot/vehicleTask/' + id,
    method: 'delete'
  })
}

// 导出车辆任务
export function exportVehicleTask(query) {
  return request({
    url: '/iot/vehicleTask/export',
    method: 'get',
    params: query
  })
}

// 检查任务是否有冲突
export function checkTaskConflict(query) {
  return request({
    url: '/iot/vehicleTask/checkTaskConflict',
    method: 'post',
    data: query
  })
}

// 创建任务
export function createTask(data) {
  return request({
    url: '/iot/vehicleTask/createTask',
    method: 'post',
    data: data
  })
}

// 切换地图
export function changeMap(data) {
  return request({
    url: '/iot/vehicleTask/changeMap',
    method: 'post',
    data: data
  })
}

// 执行任务
export function taskStart(data) {
  return request({
    url: '/iot/vehicleTask/taskStart',
    method: 'post',
    data: data
  })
}

// 同步车端任务
export function syncTask(data) {
  return request({
    url: '/iot/vehicleTask/syncTask',
    method: 'post',
    data: data
  })
}

// 强制退出任务
export function forceQuit(data) {
  return request({
    url: '/iot/vehicleTask/forceQuit',
    method: 'post',
    data: data
  })
}

// 车端回馈中控创建车端任务指令ACK
export function createTaskAck(query) {
  return request({
    url: '/iot/vehicleTask/createTaskAck',
    method: 'get',
    params: query
  })
}

// 车端回馈中控同步车端任务指令ACK
export function syncTaskAck(query) {
  return request({
    url: '/iot/vehicleTask/syncTaskAck',
    method: 'get',
    params: query
  })
}

// 下发指令删除车端任务
export function delTask(data) {
  return request({
    url: '/iot/vehicleTask/delTask',
    method: 'post',
    data: data
  })
}

// 下发指令删除车端任务ACK
export function delTaskAck(query) {
  return request({
    url: '/iot/vehicleTask/delTaskAck',
    method: 'get',
    params: query
  })
}
