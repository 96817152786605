import request from '@/utils/request'

// 查询车辆任务列表
export function listTaskScheduler(query) {
  return request({
    url: '/iot/taskScheduler/list',
    method: 'get',
    params: query
  })
}

// 修改车辆任务
export function updateTaskScheduler(data) {
  return request({
    url: '/iot/taskScheduler',
    method: 'put',
    data: data
  })
}

// 新增车辆任务
export function addTaskScheduler(data) {
  return request({
    url: '/iot/taskScheduler',
    method: 'post',
    data: data
  })
}

// 查询车辆任务详细
export function getTaskScheduler(id) {
  return request({
    url: '/iot/taskScheduler/' + id,
    method: 'get'
  })
}

// 执行任务
export function taskStart(data) {
  return request({
    url: '/iot/taskScheduler/taskStart',
    method: 'post',
    data: data
  })
}

// 强制退出任务
export function forceQuit(data) {
  return request({
    url: '/iot/taskScheduler/forceQuit',
    method: 'post',
    data: data
  })
}

// 取消车辆定时任务
export function cancelTask(data) {
  return request({
    url: '/iot/taskScheduler/cancelTask',
    method: 'post',
    data: data
  })
}

// 下发指令开始动态点位任务
export function taskStartDynamic(data) {
  return request({
    url: '/iot/taskScheduler/taskStartDynamic',
    method: 'post',
    data: data
  })
}

// 车端回馈中控开始任务指令ACK
export function taskStartAck(query) {
  return request({
    url: '/iot/taskScheduler/taskStartAck',
    method: 'get',
    params: query
  })
}

// 设置固定点任务
export function autoReset(data) {
  return request({
    url: '/iot/taskScheduler/autoReset',
    method: 'post',
    data: data
  })
}

// 取消固定点任务
export function cancelAutoReset(data) {
  return request({
    url: '/iot/taskScheduler/cancelAutoReset',
    method: 'post',
    data: data
  })
}

// 设置固定点任务ACK
export function autoResetAck(query) {
  return request({
    url: '/iot/taskScheduler/autoResetAck',
    method: 'get',
    params: query
  })
}

// 取消固定点任务ACK
export function cancelAutoResetAck(query) {
  return request({
    url: '/iot/taskScheduler/cancelAutoResetAck',
    method: 'get',
    params: query
  })
}

// 同步车端复位任务
export function autoResetGet(data) {
  return request({
    url: '/iot/taskScheduler/autoResetGet',
    method: 'post',
    data: data
  })
}

// 同步车端复位任务Ack
export function autoResetGetAck(query) {
  return request({
    url: '/iot/taskScheduler/autoResetGetAck',
    method: 'get',
    params: query
  })
}

//  获取复位任务
export function getTaskAutoReset(query) {
  return request({
    url: '/iot/taskScheduler/getTaskAutoReset',
    method: 'get',
    params: query
  })
}
